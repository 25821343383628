<template>
    <v-container>
        <v-row>
            <v-col class="text-center ma-auto" cols="12" sm="6">
                <v-card class="card-top">
                    <v-card-title class="justify-center">
                        Resend Verification
                    </v-card-title>
                    <v-card-text class="pa-4">
                        <v-text-field
                            prepend-icon="email"
                            v-model="form.username"
                            label="Username"
                            required
                            name="username"
                            @keyup.enter="resendVerificationUsername"
                        />
                    </v-card-text>
                    <v-card-actions class="px-4 pb-4">
                        <v-btn
                            class="primary white--text font-weight-bold ml-auto"
                            depressed
                            @click="resendVerificationUsername"
                            :disabled="!form.username"
                            >Send Verification Link</v-btn
                        >
                    </v-card-actions>
                </v-card>
                <v-alert
                    v-show="message"
                    class="mt-2"
                    :type="messageType"
                    v-text="message"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            form: {
                username: ""
            },
            sent: false,
            isLoading: {
                send: false
            },
            alert: "",
            message: "",
            error: null
        };
    },
    computed: {
        messageType() {
            return this.message.includes("registered") ? "error" : "info";
        }
    },
    methods: {
        async resendVerificationUsername() {
            try {
                this.isLoading.send = true;
                const res = await api.auth.resendVerificationUsername({
                    params: {
                        username: this.form.username
                    }
                });
                this.message = res.data.message;
                this.sent = true;
            } catch (err) {
                this.error = err;
            } finally {
                this.isLoading.send = false;
            }
        }
    }
};
</script>
